import React, {useState} from "react"
import {graphql} from "gatsby"
import {navigate} from 'gatsby-link'
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"
import {PrismicRichText} from "@prismicio/react"
import styled from "styled-components"
import ReCAPTCHA from "react-google-recaptcha"
import LeafletMap from "../components/LeafletMap"

const Form = styled.form`
  margin: 10px auto;
  padding-bottom: 4rem;
  max-width: var(--fixed-width);
  font-size: 1.2em;

  label {
    display: block;
    min-width: 200px;
    margin-bottom: 6px;
  }

  input, textarea {
    width: 100%;
    font-size: 1.2em;
    margin-bottom: 20px;
    min-height: 40px;
    padding: 12px;
    border: 1px solid #ddd;

    &:focus, &:active {
      outline-style: none;
      border-bottom: 2px solid var(--clr-maivita-green);
    }
  }

  textarea {
    height: 120px;
    width: 100%;
    resize: none;
  }

  h2 {
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    padding: 10px
  }
`

const Button = styled.button`
  background: var(--clr-maivita-green);
  border: var(--clr-maivita-green);
  color: white;
  cursor: pointer;
  padding: 12px;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  border: 2px solid transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  margin-top: 10px;
  width: 100%;
  font-size: 1.2em;
  text-transform: uppercase;

  :active {
    transform: scale(0.98);
    outline-style: none;
  }

  &:disabled {
    background: var(--clr-grey-8);
    cursor: default;
  }
`

const MapWrapper = styled.div`
  position: relative;
`

const Btn = styled.button`
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 6px 12px;
  background: white;
  border: none;
  border: 1px solid var(--clr-grey-8);
  z-index: 1000;
`


const RECAPTCHA_KEY= process.env.SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === 'undefined') {
    throw new Error(`Env var SITE_RECAPTCHA_KEY is undefined!`)
}

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ContactPage = ({data}) => {

    const [captchaSubmitted, setCaptchaSubmitted] = useState(false)

    const [state, setState] = useState({})

    const location = [
        61.2388869,
        24.4440822
    ]
    const recaptchaRef = React.createRef()

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()
        fetch('/', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: encode({
                'form-name': form.getAttribute('name'),
                'g-recaptcha-response': recaptchaValue,
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    if (!data) return null

    const pageContent = data.prismicContactpage || {}
    let contactForm = {};

    pageContent.data.body.forEach((bodyContent) => {
        if (bodyContent.slice_type === "contact_form") {
            contactForm = bodyContent;
        }
    })

    return (

        <>
            <SliceZone
                body={pageContent.data.body}
                page={pageContent.uid}
            />

            <Form name="contact-us"
                  method="POST"
                  data-netlify-recaptcha="true"
                  data-netlify="true"
                  action="/contact-success"
                  onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="contact-us"/>

                <PrismicRichText field={contactForm.primary.form_title?.richText}/>

                {contactForm.items.map((field, index) => {

                    if (field.field_type === 'textarea') {
                        return (
                            <div key={index}>
                                <label>{field.field_title} {field.required === 'yes' ? '*' : ''}</label>
                                <textarea name={field.field_name} required={field.required === 'yes'}
                                          onChange={handleChange}/>
                            </div>
                        )
                    } else if (field.field_type === 'submit') {

                        return (
                            <div key={index}>
                                <ReCAPTCHA ref={recaptchaRef}
                                           sitekey={RECAPTCHA_KEY}
                                           onChange={() => setCaptchaSubmitted(true)}/>
                                <Button disabled={!captchaSubmitted}
                                        type="submit">{field.field_title}</Button>
                            </div>
                        )
                    } else {
                        return (
                            <div key={index}>
                                <label>{field.field_title} {field.required === 'yes' ? '*' : ''}</label>
                                <input type={field.type} name={field.field_name} autoComplete="off"
                                       required={field.required === 'yes'} onChange={handleChange}/>
                            </div>
                        )
                    }
                })}

            </Form>

            {typeof window !== 'undefined' && <MapWrapper>

                <LeafletMap location={location}/>
                <a href='https://www.google.com/maps/dir//Maivita+Oy+Muntsilantie+19+14680+H%C3%A4meenlinna/@61.2380859,24.4440822,11z/data=!4m5!4m4!1m0!1m2!1m1!1s0x468e493c7c9c004d:0x3141e6f40cfe7de7'
                   target="_blank"
                   rel="noopener noreferrer">
                    <Btn>Reittiohjeet</Btn>
                </a>
            </MapWrapper>}

        </>
    )
}

export const Head = () => (
    <Seo title="Minnesota-hoito Maivitan yhteystiedot" />
);

export const query = graphql`
  query ContactpageQuery($lang: String) {
     prismicContactpage(lang: { eq: $lang }) {
        
        id
        uid
        lang
        data {
          title {
            richText
          }
          seo_title
          body {
            ... on PrismicContactpageDataBodyContactForm {
              id
              slice_type
              primary {
                form_title {
                  richText
                }
              }
              items {
                field_name
                field_title
                field_type
                required
              }
            }
            ... on PrismicContactpageDataBodyHero {
              id
              slice_type
              primary {
                hero_title {
                  richText
                }
                hero_content
                background_image {
                  alt
                  fluid {
                    src
                  }
                }
              }
            }
            ... on PrismicContactpageDataBodyTextSection {
              id
              slice_type
              items {
                image_title {
                  richText
                }
                image_text {
                  richText
                }
                image {
                  fluid {
                    src
                  }
                }
              }
              primary {
                section_title {
                  richText
                }
                section_text {
                  richText
                }
              }
            }
          }
        }
        
      }
  }
`

export default ContactPage;



