import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import React from "react";

import L from 'leaflet'

const LeafletMap = ({location}) => {

    if (window === undefined) return

    const tooltip = `Maivita Oy, Minnesotahoito Muntsilantie 19, 14680 HÄMEENLINNA`

    return (
        <div className='leafletContainer'>
            <MapContainer
                style={{height: '100%', width: '100%'}}
                center={location}
                zoom={10}
                maxZoom={16}
                scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png'
                />
                <Marker position={location} icon={L.icon({iconUrl: "/marker-icon.png"})}>
                    <Popup>{tooltip}</Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}

export default LeafletMap
